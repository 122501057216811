// import Alert from '../../node_modules/bootstrap/js/src/alert.js'
// import Button from '../../node_modules/bootstrap/js/src/button.js'
// import Carousel from '../../node_modules/bootstrap/js/src/carousel.js'
// import Collapse from '../../node_modules/bootstrap/js/src/collapse.js'
// import Dropdown from '../../node_modules/bootstrap/js/src/dropdown.js'
// import Modal from '../../node_modules/bootstrap/js/src/modal.js'
// import Offcanvas from '../../node_modules/bootstrap/js/src/offcanvas.js'
// import Popover from '../../node_modules/bootstrap/js/src/popover.js'
// import ScrollSpy from '../../node_modules/bootstrap/js/src/scrollspy.js'
// import Tab from '../../node_modules/bootstrap/js/src/tab.js'
// import Toast from '../../node_modules/bootstrap/js/src/toast.js'
// import Tooltip from '../../node_modules/bootstrap/js/src/tooltip.js'

export default {
    // Alert,
    // Button,
    // Carousel,
    // Collapse,
    // Dropdown,
    // Modal,
    // Offcanvas,
    // Popover,
    // ScrollSpy,
    // Tab,
    // Toast,
    // Tooltip
  }